import * as CONFIG from '../config'
import { getApikey } from './SessionToken';



export async function getVoucherImgUrl(results) {
  // const _imageUrl = async () => {
  let imgUrl = undefined
  const { apiKey } = await getApikey();
  // console.log('results', results);
  if (typeof results.voucher !== 'undefined') {
    // console.log('typeof results.voucher.image', typeof results.voucher.image);
    // console.log(' results.voucher.image',  results.voucher.image);
    if (typeof results.voucher.id !== 'undefined' && results.voucher.image !== '' && results.voucher.image !== null) {
      // imgUrl = CONFIG.API_URL + CONFIG.VOUCHER_GETIMAGE_ENDPOINT + '/' + apiKey + '/' + btoa(results.voucher.orphan ? results.voucher.id : results.voucher.vouchercode);
      imgUrl = CONFIG.API_URL + CONFIG.VOUCHER_GETIMAGE_ENDPOINT + '/' + apiKey + '/' + btoa(results.voucher.orphan ? CONFIG.orphanPrefix+results.voucher.id : results.voucher.id);
    }
  }
  return imgUrl;
}

export async function getTicketPdfUrl(ticketPdf) {
  // const _imageUrl = async () => {
  let pdfUrl = undefined
  const { apiKey } = await getApikey();
  if (typeof ticketPdf !== 'undefined' && ticketPdf !== '' && ticketPdf !== null) {
    // pdfUrl = CONFIG.API_URL + CONFIG.TICKET_GETPDF_ENDPOINT + '/' + apiKey + '/' + ticketPdf;
    pdfUrl = CONFIG.API_URL + CONFIG.TICKET_GETPDF_ENDPOINT + '/' + ticketPdf;
  }
  return pdfUrl;
}


export function getImgFlag(language) {
  const path = '/flags/'
  if (language === 'ITA') return path+'it.png';
  if (language === 'ENG') return path + 'en.png';
  if (language === 'SPA') return path + 'es.png';
  if (language === 'ESP') return path + 'es.png';
  if (language === 'TED') return path + 'de.png';
  if (language === 'GER') return path + 'de.png';
  if (language === 'FRA') return path + 'fr.png';
  return null;
}
